<template>
  <div class="model-search-form flex flex-col lg:flex-row">
    <div class="flex flex-1 flex-col lg:flex-row w-full lg:w-2/3 lg:mb-0 justify-between items-center">
      <div class="flex flex-row w-full">
        <div class="flex-1 mr-4">
          <input 
            v-model="keyword" 
            type="search" 
            :placeholder="$t('models.model_search_form.model_title')" 
            class="post-title w-full default-input-klass"
            @focus="inputFocused = true"
          >
        </div>
        <button 
          v-if="showCancelButton"
          @click="handleCancel" 
          class="cancel-btn btn btn-secondary w-[96px]"
        >
          {{ $t('models.model_search_form.cancel') }}
        </button>
        <button
          v-else
          @click="handleSearch" 
          class="btn btn-secondary w-[96px]" 
          :disabled="!keyword"
        >
          {{ $t('models.model_search_form.search') }}
        </button>
      </div>
    </div>
    <button 
      v-if="createBtn" 
      @click="handleCreate"
      class="hidden lg:inline-block lg:ml-4 btn btn-primary w-[96px]"
    >
      {{ $t('models.model_search_form.upload') }}
    </button>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import debounce from 'lodash/debounce';

export default {
  name: 'ModelSearchForm',
  props: {
    createBtn: {
      type: Boolean,
      default: false
    }
  },
  emits: ['search'],
  setup(props, { emit }) {
    const keyword = ref('');
    const hasSearched = ref(false);
    const inputFocused = ref(false);
    
    const showCancelButton = computed(() => {
      return hasSearched.value && keyword.value && !inputFocused.value;
    });

    const debouncedSearch = debounce((value) => {
      emit('search', value);
    }, 300);

    const handleSearch = () => {
      hasSearched.value = keyword.value !== '';
      inputFocused.value = false;
      debouncedSearch(keyword.value);
    };

    const handleCancel = () => {
      keyword.value = '';
      hasSearched.value = false;
      debouncedSearch('');
    };

    const handleCreate = () => {
      window.location.href = '/profile/models/new';
    };

    return {
      keyword,
      inputFocused,
      showCancelButton,
      handleSearch,
      handleCancel,
      handleCreate
    };
  }
};
</script>
